import { render, staticRenderFns } from "./sensitiveManage.vue?vue&type=template&id=d17a5928&scoped=true&"
import script from "./sensitiveManage.vue?vue&type=script&lang=ts&"
export * from "./sensitiveManage.vue?vue&type=script&lang=ts&"
import style0 from "./sensitiveManage.vue?vue&type=style&index=0&id=d17a5928&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d17a5928",
  null
  
)

export default component.exports