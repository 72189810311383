


































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { StrategyListObj } from '@/views/setting/types'

@Component({ name: 'sensitiveManage' })
export default class sensitiveManage extends VueBase {
  private tableData: Array<StrategyListObj> = []
  private pattern_type = []
  private pattern_type_map = {}
  private page = 1
  private page_size = 20
  private total = 0
  private name = ''
  private async stateChange(row: any) {
    // if (this.userInfo.role !== 1 && this.userInfo.role !== 2) {
    //   return
    // }
    this.loadingStart()
    const res = await this.services.setting.update_sensitive_info_rule({
      ...row,
      ...{ status: row.status === 0 ? 1 : 0 },
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getTableData()
    } else {
      this.$message.error(res.msg)
    }
  }

  private handleSizeChange(val: number) {
    this.page_size = val
    this.getTableData()
  }

  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  async created() {
    this.loadingStart()
    await this.getPatternType()
    await this.getTableData()
    this.loadingDone()
  }
  get userInfo(): { username: string; role: number } {
    return this.$store.getters.userInfo
  }

  private async getPatternType() {
    const res = await this.services.setting.pattern_type()
    if (res.status === 201) {
      this.pattern_type = res.data
      this.pattern_type.forEach((item: any) => {
        this.$set(this.pattern_type_map, item.id, {
          name: item.name,
          url: item.url,
        })
      })
    } else {
      this.$message.error(res.msg)
    }
  }
  private async getTableData() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.get_sensitive_info_rule({
        page: this.page,
        page_size: this.page_size,
        name: this.name,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    if (data.length === 0 && this.page > 1) {
      this.page--
      await this.getTableData()
      return
    }
    this.tableData = data
    this.total = page.alltotal
  }
  private sensitiveDialogAdd() {
    this.$router.push({ name: 'sensitive' })
  }
  private sensitiveDialogEdit(row: any) {
    this.$router.push({
      name: 'sensitive',
      query: {
        id: row.id,
      },
    })
  }
  private async sensitiveDialogDelete(row: any) {
    this.$confirm(
      this.$t('views.sensitiveManage.deleteWarning') as string,
      this.$t('views.sensitiveManage.deletePop') as string,
      {
        confirmButtonText: this.$t('views.sensitiveManage.enter') as string,
        cancelButtonText: this.$t('views.sensitiveManage.clear') as string,
        type: 'warning',
      }
    ).then(async () => {
      const res = await this.services.setting.delete_sensitive_info_rule_one({
        id: row.id,
      })
      if (res.status === 201) {
        this.$message({
          type: 'success',
          message: res.msg,
        })
        this.getTableData()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      }
    })
  }
}
